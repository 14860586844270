<template>
  <v-container fluid fill-height class="pa-0" style="position:relative;">
    <fullscreen ref="fullscreen" style="width: 100%;height: 100%;" class="grey lighten-5" @change="fullscreenChange">
      <Split :gutter-size="gutterSize" :style="'height:calc(100vh - ' + appNavHeight + 'px)'">
        <SplitArea v-show="showSplitAreaLeft" :size="splitAreaLeftSize" :min-size="300">
          <v-toolbar height="60" dense flat color="grey lighten-4" class="pr-0">
            <h1 class="title black--text">{{ $t('menu.myMessages') }}</h1>
            <v-spacer />
            <v-menu v-if="selectedStatus">
              <template v-slot:activator="{ on, attrs }">
                <v-btn v-tooltip="$t('common.filtrar')" text rounded v-bind="attrs" class="align-self-center" v-on="on">
                  {{ selectedStatus.title }}
                  <v-icon right>mdi-menu-down</v-icon>
                </v-btn>
              </template>
              <v-list>
                <v-list-item v-for="(tabItem, index) in tabs" :key="index" @click="changeSelectedStatus(tabItem)">
                  <v-list-item-title>{{ tabItem.title }}</v-list-item-title>
                </v-list-item>
              </v-list>
            </v-menu>
          </v-toolbar>

          <div v-if="isLoadingTreads" class="pa-5 text-center">
            <v-progress-circular width="2" indeterminate color="primary" />
          </div>

          <vue-perfect-scrollbar
            v-if="!isLoadingTreads"
            class="messages-treads--scroll"
            :settings="scrollSettings"
            :style="'overflow-y:auto;height:calc(100vh - ' + appNavHeight + 'px - 60px)'"
          >
            <div
              v-if="
                selectedStatus && messagesThreads[selectedStatus.type] && !messagesThreads[selectedStatus.type].length
              "
              class="pa-5 text-center grey--text"
            >
              <div>{{ $t('messages.not_have_pending') }}</div>
<!--              <v-chip-->
<!--                v-if="selectedStatus && selectedStatus.type !== 'all'"-->
<!--                class="mt-3"-->
<!--                close-->
<!--                @click:close="resetStatusFilter"-->
<!--              >-->
<!--                {{ $t('messages.reset_filter') }}:-->
<!--                {{ selectedStatus.title }}-->
<!--              </v-chip>-->
            </div>

            <v-list
              v-if="
                selectedStatus && messagesThreads[selectedStatus.type] && messagesThreads[selectedStatus.type].length
              "
              class="py-0 animate__animated animate__fadeIn"
            >
              <v-list-item-group v-model="item" color="grey darken-1">
                <template v-for="thread in messagesThreads[selectedStatus.type]">
                  <v-list-item
                    :key="thread.id"
                    :class="{
                      'grey lighten-5': !thread.unreadMessagesCount,
                    }"
                    @click="loadMessagesThread(thread.id)"
                  >
                    <v-list-item-avatar>
                      <d-avatar
                        :src="thread.professional.image"
                        :text="thread.professional.name"
                        rounded
                        :two-letter="true"
                      />
                    </v-list-item-avatar>
                    <v-list-item-content>
                      <v-list-item-title
                        :class="{
                          'font-weight-bold': thread.unreadMessagesCount,
                          'grey--text text--darken-1': !thread.unreadMessagesCount,
                        }"
                        v-text="thread.professional.name"
                      />
                      <v-list-item-subtitle class="grey--text text--darken-1" v-text="messageReceiver(thread)" />
                      <v-list-item-subtitle class="grey--text text--darken-1 body-1">
                        <v-icon
                          v-if="thread.attachedFilesCount"
                          v-tooltip="$t('mensajeria.has_attachments')"
                          size="16"
                          class="mr-1 rotate-45"
                          >mdi-paperclip</v-icon
                        >
                        {{ thread.subject }}
                      </v-list-item-subtitle>
                    </v-list-item-content>
                    <v-list-item-action>
                      <div
                        v-tooltip="
                          $moment(thread.lastMessageAt ? thread.lastMessageAt : thread.createdAt).format(
                            'dddd, DD MMMM YYYY, hh:mm:ss'
                          )
                        "
                        class="grey--text body-2"
                      >
                        {{ thread.lastMessageAt ? thread.lastMessageAt : thread.createdAt | moment('from') }}
                      </div>
                      <div>
                        <v-chip v-show="thread.status !== 'open'" color="grey lighten-3" x-small label>
                          <span v-if="thread.status === 'closed'">{{ $t('mensajeria.cerrado') }}</span>
                          <span v-else>{{ thread.status }}</span>
                        </v-chip>

                        <v-avatar
                          v-if="thread.unreadMessagesCount"
                          v-tooltip="$t('mensajeria.unread_messages')"
                          color="red"
                          size="20"
                        >
                          <span class="white--text caption">{{ thread.unreadMessagesCount }}</span>
                        </v-avatar>
                      </div>
                    </v-list-item-action>
                  </v-list-item>
                  <v-divider :key="'d' + thread.id" />
                </template>
              </v-list-item-group>
            </v-list>
            <v-pagination
              v-if="meta.totalPages > 1"
              v-model="meta.page"
              circle
              :length="meta.totalPages"
              class="mb-8"
              @input="changePage"
            />
          </vue-perfect-scrollbar>
        </SplitArea>
        <SplitArea
          v-show="showMessages"
          class="messages-right"
          :size="splitAreaRightSize"
          :min-size="300"
          @dragover="fileDragHover"
          @dragleave="fileDragHover"
          @drop="fileSelectHandler"
        >
          <v-card v-if="selectedMessagesThread" tile flat height="100%" style="position: relative">
            <v-toolbar height="60" flat dense color="grey lighten-3">
              <!--
                <v-btn icon @click="toggleFullscreen">
                  <v-icon>mdi-fullscreen</v-icon>
                </v-btn>
              -->
              <h2 class="title">{{ selectedMessagesThread['professional'].name }}</h2>
              <v-menu v-if="selectedMessagesThread && selectedMessagesThread.attachedFilesCount">
                <template v-slot:activator="{ on }">
                  <v-btn v-tooltip="$t('mensajeria.archivos_adjuntos')" depressed rounded class="mx-2" v-on="on">
                    <v-icon size="16" class="rotate-45 mr-1">mdi-paperclip</v-icon>
                    {{ selectedMessagesThread.attachedFilesCount }}
                    <v-icon right>mdi-menu-down</v-icon>
                  </v-btn>
                </template>
                <v-list>
                  <v-list-item
                    v-for="(file, index) in selectedMessagesThread.attachedFiles"
                    :key="index"
                    @click="openDocument(file)"
                  >
                    <v-list-item-content>
                      <v-list-item-title>{{ file.name }}</v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                </v-list>
              </v-menu>

              <v-spacer />
              <v-btn v-tooltip="$t('common.close')" icon @click="closePanelRight">
                <v-icon color="black">mdi-close</v-icon>
              </v-btn>
            </v-toolbar>

            <div v-if="isLoadingTread" class="pa-5 text-center">
              <v-progress-circular width="2" indeterminate color="primary" />
            </div>

            <Split direction="vertical" :gutter-size="14">
              <SplitArea :min-size="40" :size="80">
                <vue-perfect-scrollbar
                  v-if="selectedMessagesThread"
                  class="messages-tread--scroll px-0 pb-5 body-1 animate__animated animate__fadeIn"
                  :settings="scrollSettings"
                  :style="'overflow-y:auto;height:100%'"
                >
                  <!-- professional info -->
                  <v-flex class="pa-10 text-center">
                    <d-avatar
                      v-if="selectedMessagesThread"
                      v-tooltip="selectedMessagesThread['professional'].name"
                      :text="selectedMessagesThread['professional'].name"
                      :src="selectedMessagesThread['professional'].image"
                      size="70"
                      rounded
                      :two-letter="true"
                    />
                    <h2 class="headline mb-1">{{ selectedMessagesThread['professional'].name }}</h2>
                    <div class="grey--text body-2 mb-3">
                      {{ $t('user.num_colegiado') }}:
                      {{ selectedMessagesThread['professional'].collegiateNumber }}
                    </div>
                    <div class="mb-3">
                      <v-chip small label color="grey lighten-4 mx-1">{{ selectedMessageThreadSpecialty }}</v-chip>
                    </div>
                  </v-flex>

                  <!-- message grouped -->
                  <v-hover v-if="showGroupedMessages" v-slot:default="{ hover }">
                    <v-layout
                      v-tooltip="$t('messages.show_messages')"
                      :class="{ 'grey lighten-4': hover }"
                      class="pointer align-center px-4 py-4"
                      @click="isGroupedMessages = false"
                    >
                      <v-avatar size="40" color="grey lighten-2">{{ groupedMessagesCount }}</v-avatar>
                      <div class="px-4">
                        <div>
                          <strong>{{ selectedMessagesThread['professional'].name }}</strong>
                        </div>
                      </div>
                      <v-flex>
                        <v-divider class="mb-2" />
                        <v-divider />
                      </v-flex>
                    </v-layout>
                  </v-hover>

                  <!-- message -->
                  <template v-for="(message, index) in selectedMessagesThread.messages">
                    <v-layout
                      v-if="groupedMessagesCount <= index"
                      :id="'message-' + message.id"
                      :key="message.id"
                      style="max-width:700px"
                      class="px-4 py-4"
                      :class="{
                        lastMessage: index === selectedMessagesThread.messages.length - 1,
                      }"
                    >
                      <d-avatar
                        :text="selectedMessagesThread[message.writedBy].name"
                        :src="selectedMessagesThread[message.writedBy].image"
                        size="40"
                        rounded
                        :two-letter="true"
                      />
                      <div class="px-4">
                        <div>
                          <strong>{{ selectedMessagesThread[message.writedBy].name }}</strong>
                          <span
                            v-tooltip="$moment(message.createdAt).format('dddd, DD MMMM YYYY, hh:mm:ss')"
                            class="ml-3 grey--text body-2"
                            >{{ message.createdAt | moment('from') }}</span
                          >

                          <v-icon
                            v-if="!message.readed"
                            v-tooltip="$t('messages.new')"
                            size="6"
                            circle
                            rounded
                            color="blue"
                            class="ml-3"
                            >mdi-circle</v-icon
                          >
                        </div>
                        <!-- eslint-disable-next-line vue/no-v-html -->
                        <div :style="sizeScreenStyle" v-html="addLinks(message.text)" />
                        <div v-if="message.attachedFile" class="mt-4">
                          <v-chip
                            color="grey lighten-5"
                            class="blue--text"
                            @click="openDocument(getAttachedFile(message.attachedFile))"
                          >
                            <v-icon size="16" class="rotate-45 mr-2">mdi-paperclip</v-icon>
                            {{ getAttachedFile(message.attachedFile).name }}
                          </v-chip>
                        </div>
                      </div>
                    </v-layout>
                  </template>
                </vue-perfect-scrollbar>
              </SplitArea>
              <SplitArea :min-size="20" :size="20" class="elevation-5 editor-container">
                <template v-if="selectedMessagesThread.status === 'closed'">
                  <div class="alertNotMessages py-5 grey--text text-center">{{ $t('messages.message_closed') }}</div>
                </template>
                <template v-if="!isLoadingTread && selectedMessagesThread.status !== 'closed'">
                  <!-- editor -->
                  <v-toolbar
                    ref="filedrag"
                    height="200"
                    flat
                    class="pt-2"
                    @dragover="fileDragHover"
                    @dragleave="fileDragHover"
                    @drop="fileSelectHandler"
                  >
                    <v-flex grow style="max-width: calc(100% - 110px);">
                      <div v-if="attachedFile" class="pt-2">
                        <v-chip close @click:close="attachedFile = null">
                          <v-icon size="16" class="rotate-45 mr-2">mdi-paperclip</v-icon>
                          <span class="text-truncate">
                            {{ attachedFile.name }}
                          </span>
                        </v-chip>
                      </div>
                      <v-textarea
                        v-model="messageText"
                        autofocus
                        no-resize
                        full-width
                        flat
                        solo
                        background-color="white"
                        :placeholder="$t('messages.write_your_message_here')"
                        rows="4"
                        hide-details
                        :readonly="isSaving"
                      />
                    </v-flex>
                    <v-btn
                      v-show="!isSaving"
                      v-tooltip="$t('mensajeria.attach_file')"
                      class="mt-2"
                      icon
                      text
                      @click="browseFile"
                    >
                      <v-icon>mdi-paperclip</v-icon>
                    </v-btn>
                    <div class="mt-1 mb-1 mx-3">
                      <template v-if="!isSavingSuccess">
                        <v-btn
                          v-if="!isSaving"
                          v-tooltip="$t('mensajeria.enviar_mensaje')"
                          class="elevation-0"
                          :class="{ 'rotate-n45': messageText || attachedFile }"
                          :color="messageText ? 'primary' : 'white'"
                          :outlined="!messageText && !attachedFile"
                          fab
                          :disabled="!messageText && !attachedFile"
                          @click="sendMessage"
                        >
                          <v-icon class="ml-2" size="30">mdi-send</v-icon>
                        </v-btn>
                        <v-progress-circular v-if="isSaving" size="56" width="2" color="primary" indeterminate />
                      </template>

                      <check-mark v-if="isSavingSuccess" :size="56" class="checkmark d-inherit" />
                    </div>
                    <input
                      v-show="false"
                      id="newfile"
                      ref="filenew"
                      type="file"
                      accept="application/vnd.ms-excel, text/plain, application/pdf, image/*, .pdf, video/mp4, video/x-m4v, video/avi"
                      @change="fileSelectHandler"
                    />
                  </v-toolbar>
                </template>
              </SplitArea>
            </Split>
          </v-card>
        </SplitArea>
      </Split>
      <dialog-confirm :dialog="informedConsentDialog" @btnDialog="btnDialog" />
    </fullscreen>
  </v-container>
</template>
<script>
import VuePerfectScrollbar from 'vue-perfect-scrollbar';
import DAvatar from '@/components/DAvatar.vue';
import CheckMark from '@/views/messages/CheckMark.vue';
import Alerts from '@/mixins/Alerts';
import { hideIntercom } from '@/utils';
import {
  getMessagesUrl,
  getHeader,
  urlPatient,
  urlPatientFilter,
  displayNameEnv,
  getShowInformedConsentUrl,
  getAcceptInformedConsentUrl,
} from '@/config/config';
import DialogConfirm from '@/components/common/DialogConfirm.vue';
import DownloadFile from '@/mixins/DownloadFile';
import { mapActions } from 'vuex';
import { NOT_ALLOWED_FILE_TYPE_MESSAGE } from '@/utils/AllowedFileTypes';

export default {
  title: displayNameEnv + ' - ' + vm.$t('menu.myMessages'),
  components: {
    DAvatar,
    VuePerfectScrollbar,
    CheckMark,
    DialogConfirm,
  },
  mixins: [Alerts, DownloadFile],
  data() {
    return {
      authUser: JSON.parse(window.localStorage.getItem('auth_ehealth')),
      isLoadingTreads: false,
      isLoadingTread: true,
      isSaving: false,
      isSavingSuccess: false,
      isFullscreen: false,
      overlay: false,
      currentPage: 1,
      perPage: 10,
      item: null,
      messagesThreads: [],
      messageText: '',
      showMessages: false,
      attachedFile: null,
      tab: null,
      tabs: [
        { title: vm.$t('messages.all'), type: 'all' },
        { title: vm.$t('messages.new'), type: 'news' },
        { title: vm.$t('messages.unsolved'), type: 'unsolved' },
        { title: vm.$t('messages.solved'), type: 'solved' },
      ],
      selectedStatus: null,
      selectedMessagesThreadId: null,
      selectedMessagesThread: null,
      scrollSettings: {
        maxScrollbarLength: 160,
      },
      showSplitAreaLeft: true,
      isGroupedMessages: true,
      minUngroupedMessages: 3,
      shouldCheckInformedConsent: true,
      informedConsentDialog: {
        showHtml: true,
        show: false,
        persistent: true,
        title: '',
        text: '',
        maxwidth: 800,
        idSelected: 0,
        index: 0,
        buttons: [
          { text: vm.$t('common.no'), color: 'grey', click: 'cancel' },
          { text: vm.$t('common.confirm'), color: 'red darken-1', click: 'ok' },
        ],
      },
      informedConsentId: null,
      meta: {
        count: 0,
        limit: 0,
        offset: 0,
        page: 1,
        total: 0,
        totalPages: 1,
      },
      sizeScreenStyle: '',
      threadListPollingTimer: null,
      selectedThreadPollingTimer: null,
      lastCallToListThreadsApi: 0,
      lastCallToGetSelectedThreadApi: 0,
    };
  },
  computed: {
    splitAreaLeftSize() {
      return this.showMessages ? 40 : 100;
    },
    gutterSize() {
      return this.showMessages && this.showSplitAreaLeft ? 8 : 0;
    },
    splitAreaRightSize() {
      return !this.showSplitAreaLeft ? 100 : 60;
    },
    appNavHeight() {
      return this.$vuetify.breakpoint.mobile ? 56 : 64;
    },

    groupedMessagesCount() {
      if (!this.isGroupedMessages) {
        return 0;
      }
      return parseInt(this.readMessagesCount - this.minUngroupedMessages);
    },
    messagesCount() {
      return this.selectedMessagesThread.messages.length;
    },
    readMessagesCount() {
      return this.messagesCount - this.unreadMessagesCount;
    },
    readMessages() {
      return this.selectedMessagesThread.messages.filter(item => item.readed === true);
    },
    unreadMessages() {
      return this.selectedMessagesThread.messages.filter(item => item.readed === false);
    },
    unreadMessagesCount() {
      return this.unreadMessages.length;
    },
    ungroupedMessages() {
      if (this.isGroupedMessages) {
        return this.unreadMessages;
      }
      return this.selectedMessagesThread.messages;
    },
    showGroupedMessages() {
      return this.isGroupedMessages && this.selectedMessagesThread.messages.length > 3 && this.groupedMessagesCount > 0;
    },
    selectedMessageThreadSpecialty() {
      const indexOf = this.selectedMessagesThread.professional.specialtiesIds.indexOf(
        this.selectedMessagesThread.specialtyId
      );

      if (indexOf === -1) {
        return null;
      }

      return this.selectedMessagesThread.professional.specialties[indexOf];
    },
  },
  watch: {
    showMessages(val) {
      if (val) {
        this.onResize();
      }
    },
    selectedMessagesThreadId(newValue) {
      if (null === newValue) {
        return;
      }

      if (this.$route.params.id === newValue) {
        return;
      }

      this.$router.push({ name: 'myMessages', params: { id: newValue } });
    },
  },
  created() {
    this.threadListPollingTimer = window.setInterval(() => {
      if (Date.now() - this.lastCallToListThreadsApi < 12000) {
        return;
      }

      this.updateMessagesThreads();
    }, 15000);

    this.selectedThreadPollingTimer = window.setInterval(() => {
      if (Date.now() - this.lastCallToGetSelectedThreadApi < 8000) {
        return;
      }

      if (null === this.selectedMessagesThreadId) {
        return;
      }

      if (0 === this.selectedMessagesThreadId) {
        return;
      }

      if (false === this.showMessages) {
        return;
      }

      this.loadMessagesThread(this.selectedMessagesThreadId, true);
    }, 12000);
  },
  mounted() {
    this.resetStatusFilter();
    this.loadMessagesThreads();
    if (typeof this.$route.params.id !== 'undefined' && this.$route.params.id > 0) {
      this.loadMessagesThread(this.$route.params.id);
    }
    setTimeout(() => {
      hideIntercom();
    }, 2000);
    this.$nextTick(() => {
      window.addEventListener('resize', this.onResize);
    });
  },
  beforeDestroy() {
    window.clearInterval(this.threadListPollingTimer);
    window.clearInterval(this.selectedThreadPollingTimer);
  },
  methods: {
    async openDocument(document) {
      await this.download({
        url: document.url ? document.url : document.file,
        name: document.name ? document.name : document.fileName,
        type: document.type ? document.type : document.fileType,
        download: true,
      });
    },
    ...mapActions('layout', ['showAlert']),
    onResize() {
      setTimeout(() => {
        this.sizeScreenStyle =
          null !== document.querySelector('.messages-right')
            ? 'width: ' + (document.querySelector('.messages-right').clientWidth - 100) + 'px'
            : '';
      }, 500);
    },
    toggleFullscreen() {
      this.$refs['fullscreen'].toggle();
    },
    fullscreenChange(fullscreen) {
      this.fullscreen = fullscreen;
    },
    changePage(page) {
      this.currentPage = page;
      this.loadMessagesThreads();
    },
    closePanelRight() {
      this.showMessages = false;
      this.item = null;
      this.selectedMessagesThread = null;
      if (this.$vuetify.breakpoint.mobile) {
        this.showSplitAreaLeft = true;
      }
    },
    sendMessage: function() {
      this.isSaving = true;
      this.showMessages = true;

      const messageData = {
        message: this.messageText,
      };

      if (this.attachedFile) {
        messageData.attachedFile = this.attachedFile;
      }

      this.$http
        .post(urlPatient(getMessagesUrl + '/' + this.selectedMessagesThreadId), messageData, {
          headers: getHeader(),
        })
        .then(() => {
          this.messageText = null;
          this.attachedFile = null;
          this.isSavingSuccess = true;
          setTimeout(() => {
            this.isSavingSuccess = false;
          }, 3000);

          this.loadMessagesThread(this.selectedMessagesThreadId, true);
        })
        .catch(err => {
          if (NOT_ALLOWED_FILE_TYPE_MESSAGE === err.body.message) {
            this.showAlert({
              color: 'error',
              icon: 'mdi-alert-circle',
              message: this.$t('errors.file_uploaded_invalid'),
            });
          }
          this.$log.error(err);
          if (err && err.response && err.response.data) {
            this.toastError(err.response.data.detail);
          }
        })
        .finally(() => {
          this.isSaving = false;
        });
    },
    loadMessagesThread(id, silent = false) {
      this.lastCallToGetSelectedThreadApi = Date.now();

      if (this.$vuetify.breakpoint.mobile) {
        this.showSplitAreaLeft = false;
      }
      this.selectedMessagesThreadId = id;
      this.isLoadingThread = true;
      this.showMessages = silent;
      this.$http
        .get(urlPatient(getMessagesUrl + '/' + id), {
          headers: getHeader(),
        })
        .then(res => {
          if (!silent) {
            this.isGroupedMessages = true;
          }
          this.selectedMessagesThread = res.body;
          const showInformedConsent = this.authUser.show_informed_consent;
          if (showInformedConsent && this.selectedMessagesThread.specialtyId !== 0 && this.shouldCheckInformedConsent) {
            this.checkInformedConsent();
          } else {
            this.showMessages = true;
          }
        })
        .catch(err => {
          this.$log.error(err);
          if (err && err.response && err.response.data) {
            this.toastError(err.response.data.detail);
          }
        })
        .finally(() => {
          this.isLoadingTread = false;
          if (!silent) {
            setTimeout(() => {
              this.scrollToLastMessage();
            }, 500);
          }
        });
    },
    loadMessagesThreads() {
      this.isLoadingTreads = true;
      this.updateMessagesThreads();
    },
    updateMessagesThreads() {
      this.lastCallToListThreadsApi = Date.now();

      const filter = '?limit=' + this.perPage + '&page=' + this.currentPage + '&type=' + this.selectedStatus.type;
      this.$http
        .get(urlPatientFilter(getMessagesUrl, filter), {
          headers: getHeader(),
        })
        .then(res => {
          this.setMessagesTreads(res.body.data);
          this.meta = res.body._meta;
          this.meta.totalPages = Math.ceil(this.meta.total / this.perPage);
        })
        .catch(err => {
          this.$log.error(err);
          if (err && err.response && err.response.data) {
            this.toastError(err.response.data.detail);
          }
        })
        .finally(() => {
          this.isLoadingTreads = false;
        });
    },

    setMessagesTreads(data) {
      const news = [];
      const unsolved = [];
      const solved = [];
      data.forEach(function(item) {
        if (item.unreadMessagesCount > 0) {
          news.push(item);
        }
        if (item.status === 'open') {
          unsolved.push(item);
        } else if (item.status === 'closed') {
          solved.push(item);
        }
      });
      this.messagesThreads['news'] = news;
      this.messagesThreads['unsolved'] = unsolved;
      this.messagesThreads['solved'] = solved;
      this.messagesThreads['all'] = data;
      if (this.messagesThreads['news'].length === 0) {
        this.tab = this.messagesThreads['unsolved'].length > 0 ? 1 : 3;
      }
    },

    browseFile() {
      this.$refs.filenew.click();
    },

    fileDragHover(e) {
      e.stopPropagation();
      e.preventDefault();
      const cl = this.$refs.filedrag.classList;
      if (cl) {
        if (e.type === 'dragover') {
          cl.add('filedrag-hover');
        } else {
          cl.remove('filedrag-hover');
        }
      }
    },

    fileSelectHandler(e) {
      this.fileDragHover(e);
      const files = e.target.files || e.dataTransfer.files;
      const attachedFile = files[0];
      const reader = new FileReader();
      reader.onload = e => {
        this.attachedFile = {
          name: attachedFile.name,
          content: e.target.result,
        };
      };
      reader.readAsDataURL(files[0]);
    },

    getAttachedFile(id) {
      return this.selectedMessagesThread.attachedFiles.find(item => item.id.toString() === id.toString());
    },

    resetStatusFilter() {
      this.selectedStatus = this.tabs[0];
    },

    checkInformedConsent() {
      const professional = this.selectedMessagesThread['professional'];
      const messageData = {
        patientId: this.authUser.patient.id,
        companyId: professional.companyId,
        specialtyId: this.selectedMessagesThread.specialtyId,
      };
      this.$http
        .post(getShowInformedConsentUrl, messageData, {
          headers: getHeader(),
        })
        .then(response => {
          this.informedConsentDialog.text = response.data.content;
          this.informedConsentId = response.data.id ? response.data.id : null;
          this.informedConsentDialog.show = true;
        })
        .catch(() => {
          this.showMessages = true;
          this.shouldCheckInformedConsent = false;
        });
    },

    btnDialog(action) {
      this.informedConsentDialog.show = false;
      if (action === 'ok') {
        this.showMessages = true;

        const messageData = { id: this.informedConsentId };

        this.$http.post(getAcceptInformedConsentUrl, messageData, {
          headers: getHeader(),
        });
      } else {
        this.closePanelRight();
      }
    },

    addLinks(text) {
      const urlExpReg = /(ftp|http|https):\/\/(\w+:{0,1}\w*@)?(\S+)(:[0-9]+)?(\/|\/([\w#!:.?+=&%@!\-\/]))?/gi;
      const emailPattern = /[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}/gi;

      let textReplaced = text.replace(
        emailPattern,
        matchedStr => `<a target="_blank" href=mailto:"${matchedStr}">${matchedStr}</a>`
      );

      textReplaced = textReplaced.replace(
        urlExpReg,
        matchedUrl => `<a target="_blank" href="${matchedUrl}">${matchedUrl}</a>`
      );

      return textReplaced;
    },

    messageReceiver(thread) {
      return thread.patient.id === this.authUser.patient.id
        ? this.$t('messages.to_me')
        : `${this.$t('messages.to')}: ${thread.patient.name} ${thread.patient.surname}`;
    },

    changeSelectedStatus(tabItem) {
      this.selectedStatus = tabItem;
      this.updateMessagesThreads();
    },

    scrollToLastMessage() {
      const el = this.$el.getElementsByClassName('lastMessage')[0];

      if (el) {
        el.scrollIntoView();
      }
    },
  },
};
</script>
<style scope>
.split {
  overflow: hidden;
}
.gutter:hover {
  background-color: rgba(0, 0, 0, 0.02);
}
.messages-right .gutter {
  background-color: transparent;
  margin-bottom: -14px;
  z-index: 2;
  position: absolute;
  width: 100%;
}

.editor-container .v-toolbar__content {
  align-items: unset;
}
</style>
